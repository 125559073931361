import apiService from '../services/apiService'
import { Chart } from 'chart.js'
import annotationPlugin from 'chartjs-plugin-annotation'
import 'chartjs-adapter-date-fns'

Chart.register(annotationPlugin)
//For Future use
const time_periods = {
    H1: 5,
    H2: 5,
    H3: 5,
    H4: 5,
    H12: 30,
    D1: 60,
    D3: 60,
    W1: 1440,
    W3: 1440
}

export const createMetricsData = (
    timeFrame,
    startTime,
    endTime,
    title,
    label,
    entries,
    color,
    chartType = 'bar',
    dataKey = 'Sum',
    threshold = null,
    fill = false,
    description = 'No description'
) => {
    const datasets = [
        {
            label: `${label} (${entries[0]?.Unit === 'Count' ? 'count' : 'ms'})`,
            data: entries.map((entry) => entry[dataKey]),
            backgroundColor: color,
            borderColor: color,
            fill: fill,
            threshold,
            description
        }
    ]

    return {
        title,
        chartType,
        metricsData: {
            labels: entries.map((entry) => entry.Timestamp),
            datasets
        },
        threshold, // Store threshold for dynamic chartOptions
        startTime,
        endTime
    }
}

export const fetchData = async (endpoint, timeFrame, metricsList) => {
    const response = await apiService.post(
        endpoint,
        {
            metrics: metricsList,
            timeFrame: timeFrame
        },
        {
            headers: {
                authorizationToken: localStorage.getItem('accessToken')
            },
            timeout: 20000
        }
    )
    return response.data.body.response
}

export const fetchDataByDateTime = async (endpoint, dateTimeRange, metricsList) => {
    const response = await apiService.post(
        endpoint,
        {
            metrics: metricsList,
            startTime:dateTimeRange.startTime,
            endTime: dateTimeRange.endTime
        },
        {
            headers: {
                authorizationToken: localStorage.getItem('accessToken')
            },
            timeout: 20000
        }
    )
    return response.data.body.response
}
export const chartOptions = (metricsData) => {
    const annotations = {}

    // Validate and filter valid dates
    const validStartTimes = metricsData.map((data) => new Date(data.startTime)).filter((date) => !isNaN(date.getTime()))
    const validEndTimes = metricsData.map((data) => new Date(data.endTime)).filter((date) => !isNaN(date.getTime()))
    // Calculate min and max times
    const minTime = validStartTimes.length > 0 ? new Date(Math.min(...validStartTimes)) : new Date()
    const maxTime = validEndTimes.length > 0 ? new Date(Math.max(...validEndTimes)) : new Date()

    let chartOptionsvalue = {
        responsive: true,
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Time (UTC-Hours)'
                },
                type: 'time',
                time: {
                    tooltipFormat: 'yyyy-MM-dd HH:mm',
                    unit: 'minute',
                    stepSize: 30, // 30-minute intervals
                    displayFormats: {
                        minute: 'yyyy-MM-dd HH:mm', // Format for axis labels
                        hour: "HH:mm'UTC'"
                    }
                },
                min: minTime, // Set min to the global minimum time
                max: maxTime // Set max to the global maximum time
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Metric Value'
                },

                ticks: {
                    callback: function (value) {
                        if (value === 1000000) return '1M'
                        if (value === 100000) return '100K'
                        if (value === 10000) return '10K'
                        if (value === 1000) return '1K'
                        if (value === 100) return '100'
                        if (value === 10) return '10'
                        if (value === 0) return '0'
                        return null
                    }
                }
            }
        },
        plugins: {
            annotation: {
                annotations: annotations
            },
            plugins: {
                tooltip: {
                    callbacks: {
                        label: function (tooltipItem) {
                            const utcTime = new Date(tooltipItem.raw).toISOString().substring(11, 16) + ' UTC'
                            return `Time: ${utcTime}`
                        }
                    }
                }
            }
        },
        legend: { display: true }
    }

    return chartOptionsvalue
}

export const timeFrameOptions = [
    { value: 'H1', label: '1H' },
    { value: 'H3', label: '3H' },
    { value: 'H12', label: '12H' },
    { value: 'D1', label: '1D' },
    { value: 'D3', label: '3D' }
]
