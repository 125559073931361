import React from 'react'

const NotFound = () => {
    return (
        <div
            style={{
                color: 'blue',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <h1 style={{ fontSize: '2rem', textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)' }}>
                <span style={{ color: 'rgba(255, 0, 0)' }}>Error 404 Page Not Found</span>
                
            </h1>
            <div>
                <button
                    style={{
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        color: 'white',
                        padding: '0.5rem 1rem',
                        borderRadius: '5px',
                        border: 'none',
                        cursor: 'pointer',
                        transition: 'all 0.3s ease-in-out'
                    }}
                    onClick={() => window.history.back()}
                >
                    Go To Home Page
                </button>
            </div>
        </div>
    )
}

export default NotFound
