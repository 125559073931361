import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../context/AuthContext'
import { LOGIN_CONSTANTS } from '../utils/constants'
import ApiService from '../services/apiService'
import themevector from '../assets/images/Theme_vector_img.avif'
import Header from '../components/Header'

const Login = () => {
    const [credentials, setCredentials] = useState({ username: '', password: '' })
    const { login } = useContext(AuthContext)
    const [error, setError] = useState('')
    const navigate = useNavigate()
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleSubmit = async (e) => {
        e.preventDefault()
        if (credentials.username === '' || credentials.password === '') {
            setError(LOGIN_CONSTANTS.errorMsg)
        } else {
            setError('')
            setIsSubmitting(true)
        }
        try {
            const response = await ApiService.post('/auth/signin', credentials)

            if (response.data.body.response?.AuthenticationResult.AccessToken) {
                const userData = {
                    name: credentials.username
                    // email: credentials.username,
                }
                login(
                    response.data.body.response.AuthenticationResult.AccessToken,
                    response.data.body.response.AuthenticationResult.RefreshToken,
                    userData
                )
                navigate('/dashboard/related-content')
            } else {
                setError('Invalid Email address or password')
              
            }
        } catch (error) {
            console.error('Login failed', error)
            setError('Invalid Email address or password. Please try again.')
        } finally {
            setIsSubmitting(false)
        }
    }

    const handleClose = () => {
        setError('')
        setCredentials({ username: '', password: '' })
    }

    return (
        <>
            <Header />

            <div className="flex items-center justify-center min-h-screen bg-opacity-70 bg-gray-100">
                <div className="flex w-full max-w-4xl bg-gray rounded-lg shadow-lg overflow-hidden">
                    <div className="w-full lg:w-1/2 p-8">
                        <h2 className="mb-2 text-3xl font-bold text-gray-900">{LOGIN_CONSTANTS.title}</h2>
                        <p className="mb-6 text-gray-500">{LOGIN_CONSTANTS.helpText}</p>
                        <form className="space-y-4" onSubmit={handleSubmit}>
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    {LOGIN_CONSTANTS.labelEmailaddress}
                                </label>
                                <input
                                    id="email"
                                    type="email"
                                    placeholder={LOGIN_CONSTANTS.EmailaddressPlaceholder}
                                    className="w-full p-3 mt-1 border border-gray-300 rounded-lg focus:border-purple-500 sm:text-sm"
                                    value={credentials.username}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 40) {
                                            setCredentials({ ...credentials, username: e.target.value })
                                        }
                                    }}
                                    style={{ color: 'black' }}
                                />
                            </div>
                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    {LOGIN_CONSTANTS.labelPassword}
                                </label>
                                <input
                                    type="password"
                                    id="password"
                                    placeholder={LOGIN_CONSTANTS.passwordPlaceholder}
                                    className="w-full p-3 mt-1 border border-gray-300 rounded-lg  focus:border-purple-500 sm:text-sm"
                                    value={credentials.password}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 15) {
                                            setCredentials({ ...credentials, password: e.target.value })
                                        }
                                    }}
                                    style={{ color: 'black' }}
                                />
                            </div>
                            <div className="flex items-center justify-between">
                                <div className="flex items-center"></div>
                                {/* TODO: Add Forgot Password functionality */}

                                {/* <a href="#" className="text-sm font-medium text-purple-600 hover:text-purple-500">
                                    Forgot password?
                                </a> */}
                            </div>
                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            <button
                                className="w-full py-3 text-sm font-medium text-white  rounded-lg bg-purple-900 hover:bg-purple-700 focus:outline-none"
                                type="submit"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? 'Sigining in ....' : LOGIN_CONSTANTS.buttonText}
                            </button>
                        </form>
                        {error && (
                            <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-gray-900 bg-opacity-50">
                                <div className="max-w-md mx-auto p-4 bg-white rounded shadow-md">
                                    <h2 className="text-lg font-bold mb-2">Login Error</h2>
                                    <p className="text-gray-600">{error}</p>
                                    <button
                                        onClick={handleClose}
                                        className="w-full p-2 bg-purple-900 hover:bg-purple-700 text-white rounded  mt-4"
                                    >
                                        OK
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="hidden lg:flex w-1/2 bg-purple-400 items-center shadow justify-center">
                        <img
                            style={{ width: '100%', height: '100%' }}
                            src={themevector}
                            alt="Theme Illustration"
                            className="w-3/4"
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Login
