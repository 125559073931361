import React, { useContext } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import AuthProvider, { AuthContext } from './context/AuthContext'
import Login from './pages/Login'
import Dashboard from './pages/Dashboard'
import ProtectedRoute from './pages/ProtectedRoute'
import Footer from './components/Footer'
import RelatedContent from './pages/RelatedContent'
import Storylines from './pages/Storylines'
import Ingestion from './pages/Ingestion'
import NotFound from './pages/NotFound' // Import NotFound component

function App() {
    const { authToken, dashboardloading } = useContext(AuthContext)

    return (
        <Router>
            <AuthProvider>
                <Routes>
                    {/* Redirect to /dashboard if user is authenticated */}
                    <Route
                        path="/"
                        element={
                            dashboardloading && authToken ? (
                                <Navigate to="/dashboard/related-content" replace />
                            ) : (
                                <Login />
                            )
                        }
                    />
                    <Route path="/login" element={<Login />} />

                    {/* Protected dashboard route with nested routes for each page */}
                    <Route
                        path="/dashboard"
                        element={
                            <ProtectedRoute>
                                <Dashboard />
                            </ProtectedRoute>
                        }
                    >
                        {/* Nested routes under /dashboard */}
                        <Route path="related-content" element={<RelatedContent />} />
                        <Route path="storylines" element={<Storylines />} />
                        <Route path="ingestion" element={<Ingestion />} />
                    </Route>
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <Footer />
            </AuthProvider>
        </Router>
    )
}

export default App
