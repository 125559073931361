import React, { useContext, useState } from 'react'
import { Avatar, IconButton, Typography } from '@mui/material'
import LogoutIcon from '@mui/icons-material/Logout'
import AccountTreeIcon from '@mui/icons-material/AccountTree'
import DomainVerificationIcon from '@mui/icons-material/DomainVerification'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import { NavLink } from 'react-router-dom'
import logo from '../assets/images/logo-icon-darkbg.webp'
import { AuthContext } from '../context/AuthContext'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

const Navbar = () => {
    const { logout, user } = useContext(AuthContext)
    const [isLoggingOut, setIsLoggingOut] = useState(false)
    const [menuOpen, setMenuOpen] = useState(false)

    const handleLogout = async () => {
        setIsLoggingOut(true)
        try {
            await logout()
            window.location.href = '/login'
        } catch (error) {
            console.log('error: ', error)
        } finally {
            setIsLoggingOut(false)
        }
    }

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const getInitials = (name) => {
        if (!name) {
            return ''
        }
        const firstName = name.split(' ')
        const firstLetter = firstName[0][0].toUpperCase()
        const secondLetter = firstName[1] ? firstName[1][0].toUpperCase() : ''
        return firstLetter + secondLetter
    }
    return (
        <header className="flex items-center justify-between px-6 py-3 bg-white border-b border-gray-200">
            <div className="flex items-center space-x-6">
                <img src={logo} alt="Logo" className="h-8 w-8" style={{ borderRadius: '50%' }} />
                <div className="lg:hidden">
                    <IconButton onClick={toggleMenu}>{menuOpen ? <CloseIcon /> : <MenuIcon />}</IconButton>
                </div>
                {/* Navigation Links */}
                <nav className={`lg:flex space-x-4 ${menuOpen ? 'block' : 'hidden'} lg:block`}>
                    <NavLink
                        to="/dashboard/related-content"
                        className={({ isActive }) =>
                            `text-gray-600 hover:text-black ${isActive ? 'text-blue-600 font-bold' : ''}`
                        }
                    >
                        <AnalyticsIcon className="mr-1" />
                        Related Content
                    </NavLink>
                    <NavLink
                        to="/dashboard/storylines"
                        className={({ isActive }) =>
                            `text-gray-600 hover:text-black ${isActive ? 'text-blue-600 font-bold' : ''}`
                        }
                    >
                        <AccountTreeIcon className="mr-1" />
                        Storylines
                    </NavLink>
                    <NavLink
                        to="/dashboard/ingestion"
                        className={({ isActive }) =>
                            `text-gray-600 hover:text-black ${isActive ? 'text-blue-600 font-bold' : ''}`
                        }
                    >
                        <DomainVerificationIcon className="mr-1" />
                        Ingestion
                    </NavLink>
                </nav>
            </div>
            <div className="flex items-center space-x-4">
                {/* <Avatar className="bg-yellow-500 text-white">S</Avatar> */}
                {/* <Avatar className="bg-yellow-500 text-orange-600">{!user ? 'S' : getInitials(user.name)}</Avatar> */}

                <Avatar className="bg-yellow-500 text-white">{getInitials(user?.name || '')}</Avatar>

                <IconButton onClick={handleLogout}>
                    {isLoggingOut ? (
                        <Typography variant="body2" className="text-gray-600">
                            Logging out...
                        </Typography>
                    ) : (
                        <LogoutIcon className="text-gray-600" />
                    )}
                </IconButton>
            </div>
        </header>
    )
}

export default Navbar
