// API Service Wrapper Class
import axios from 'axios'
import { apiConfig as config } from '../config'

class ApiService {
    constructor() {
        this.api = axios.create({
            baseURL: config.apiBaseUrl,
            headers: {
                Accept: config.headers.Accept,
                'Content-Type': config.headers['Content-Type']
            }
        })
    }

    //Generic method for POST Requests
    post(endpoint, data, config = {}) {
        return this.api.post(endpoint, data, config)
    }

    // Method for GET requests
    get(endpoint, config = {}) {
        return this.api.get(endpoint, config)
    }
}

// Named export for the class
export { ApiService };
const apiService = new ApiService()

export default apiService
