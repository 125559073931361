export const LOGIN_CONSTANTS = {
    title: 'Welcome back',
    helpText: 'Please enter your details',
    labelEmailaddress: 'Email address',
    labelPassword: 'Password',
    EmailaddressPlaceholder: 'Email address',
    passwordPlaceholder: 'Password',
    buttonText: 'Sign in',
    errorMsg: 'Please enter both Email address and password'
}
