import React, { createContext, useState, useEffect, useCallback, useMemo } from 'react'
import tokenUtils from '../utils/tokenUtils'
import axiosInstance from '../services/axiosInstance' // Import Axios instance
import PropTypes from 'prop-types'

export const AuthContext = createContext({
    authToken: null,
    refreshToken: null,
    dashboardLoading: true,
    user: null,
    logout: () => {},
    login: () => {}
})

const AuthProvider = ({ children }) => {
    const [authToken, setAuthToken] = useState(localStorage.getItem('accessToken'))
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'))
    const [dashboardLoading, setDashboardLoading] = useState(true)
    const [user, setUser] = useState(null)
    const logout = useCallback(async () => {
        try {
            await axiosInstance.get('/auth/signout')
        } catch (error) {
            console.error('Error during logout:', error)
        } finally {
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
            setAuthToken(null)
            setRefreshToken(null)
            setDashboardLoading(false)
            setUser(null)
        }
    }, [])

    
    const refreshAccessToken = useCallback(async () => {
        if (!refreshToken) {
            logout()
            return
        }
        try {
            const response = await axiosInstance.get('/auth/refresh-token')

            const newAccessToken = response?.data?.body?.response?.AuthenticationResult?.AccessToken
            if (newAccessToken) {
                setAuthToken(newAccessToken)
                localStorage.setItem('accessToken', newAccessToken)
            } else {
                logout()
            }
        } catch {
            logout()
        } finally {
            setDashboardLoading(false)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [refreshToken]);

    useEffect(() => {
        if (authToken && refreshToken) {
            if (tokenUtils.checkTokenExpired(authToken) || tokenUtils.checkTokenAboutToExpire(authToken, 300)) {
                refreshAccessToken()
            } else {
                setDashboardLoading(false)
            }
        } else {
            setDashboardLoading(false)
        }
    }, [authToken, refreshToken, refreshAccessToken])

    const login = useCallback((accessToken, refreshToken, userData) => {
        localStorage.setItem('accessToken', accessToken)
        localStorage.setItem('refreshToken', refreshToken)
        setAuthToken(accessToken)
        setRefreshToken(refreshToken)
        setUser(userData)
    }, [])

    const authContextValue = useMemo(
        () => ({
            authToken,
            refreshToken,
            user,
            login,
            logout,
            dashboardLoading
        }),
        [authToken, refreshToken, user, login, logout, dashboardLoading]
    )
    return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired
}
export default AuthProvider
