/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react'
import { createMetricsData, fetchData,fetchDataByDateTime } from '../utils/metricsUtils'

// Custom hook to fetch metrics data from api and format it for use in charts display
const useFetchMetrics = (endpoint, selectedTimeFrame, metricsConfig, autoRefreshInterval = null, navigate,dateTimeRange) => {
    const [chartsData, setChartsData] = useState([])
    const [loading, setLoading] = useState(false)
    const [metricsData, setMetricsData] = useState({})

    const fetchMetrics = useCallback(async () => {
        setLoading(true)
        try {
            let metrics
            let response
            if (dateTimeRange) {
                response = await fetchDataByDateTime(endpoint, dateTimeRange, Object.keys(metricsConfig))
            } else {
                response = await fetchData(endpoint, selectedTimeFrame, Object.keys(metricsConfig))
            }
            metrics = response?.metrics
            if (endpoint === '/dashboard/ingestion') {
                metrics.IngestionLatency = metrics.IngestionLatency.map((entry) => {
                    const updatedEntry = { ...entry }
                    Object.keys(updatedEntry).forEach((key) => {
                        if (typeof updatedEntry[key] === 'number' && key !== 'TimeStamp') {
                            updatedEntry[key] = updatedEntry[key] / 60000
                        }
                    })
                    return updatedEntry
                })
            }

            if (!metrics || Object.keys(metrics).length === 0) {
                setChartsData([])
                setMetricsData({})
                return
            }
            setMetricsData(metrics)
            const data = Object.keys(metricsConfig)
                .map((metricKey) => {
                    const config = metricsConfig[metricKey]
                    const metricData = metrics[metricKey]

                    return (
                        metricData &&
                        createMetricsData(
                            selectedTimeFrame,
                            response.timeRange.START_TIME,
                            response.timeRange.END_TIME,
                            config.title || config.label,
                            config.label,
                            metrics[metricKey],
                            config.color || 'rgba(0, 123, 255, 0.5)',
                            config.type || 'bar',
                            config.aggregation || 'Sum',
                            config.threshold,
                            config.fill || false,
                            response.Description[metricKey]
                        )
                    )
                })
                .filter(Boolean)
            setChartsData(data)
        } catch (error) {
            if (error.response) {
                const status = error.response.status
                console.error('Error fetching metrics data:', error)
                if (status === 403) {
                    if (navigate) {
                        navigate('/login')
                    }
                } else if (status === 400 || status === 500) {
                    console.error('Error fetching metrics data:', error)
                    alert('Internal Server Error')
                }
                // Handle non-Axios errors
                else console.error('Unexpected error:', error)
                alert('An unexpected error occurred. Please try again later.')
            }
        } finally {
            setLoading(false)
        }
    }, [endpoint, selectedTimeFrame, metricsConfig, navigate, dateTimeRange])

    useEffect(() => {
        fetchMetrics()
        if (autoRefreshInterval) {
            const intervalId = setInterval(fetchMetrics, autoRefreshInterval)

            return () => {
                clearInterval(intervalId)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTimeFrame, autoRefreshInterval, dateTimeRange])
    return { chartsData, loading, metricsData }
}

export default useFetchMetrics
