import React from 'react'
import { Card, CardContent, Typography } from '@mui/material'

// Define a reusable Ingestion Metric Card component
const MetricCardComponent = ({ title, value }) => (
    <Card
        sx={{
            '&.MuiCard-root': {
                width: '20rem',
                height: '10rem',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '0px 16px 28px -1px rgba(5, 28, 44, 0.40)'
            }
        }}
    >
        <CardContent>
            <Typography
                variant="h6"
                style={{
                    fontWeight: 'bold',
                    fontSize: '18px',
                    textAlign: 'center',
                    color: '#979494',
                    marginBottom: '1rem'
                }}
            >
                {title}
            </Typography>
            <Typography
                variant="h5"
                style={{
                    fontWeight: 'bold',
                    fontSize: '38px',
                    fontFamily: 'monospace',
                    color: 'black',
                    textAlign: 'center'
                }}
            >
                {value || '0'}
            </Typography>
        </CardContent>
    </Card>
)

export default MetricCardComponent
