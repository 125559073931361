import axios from 'axios'
import { apiConfig as config } from '../config'

const axiosInstance = axios.create({
    baseURL: config.apiBaseUrl,
    headers: {
        Accept: config.headers.Accept,
        'Content-Type': config.headers['Content-Type']
    }
})

axiosInstance.interceptors.request.use(
    (requestConfig) => {
        const refreshToken = localStorage.getItem('refreshToken')
        const accessToken = localStorage.getItem('accessToken')
        if (refreshToken) requestConfig.headers['refreshToken'] = refreshToken
        if (accessToken) requestConfig.headers['authorizationToken'] = accessToken
        return requestConfig
    },
    (error) => Promise.reject(error)
)

export default axiosInstance
