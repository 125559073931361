import  {jwtDecode } from 'jwt-decode'

const epochToDateTime = (epochTime) => {
    if (epochTime < 0) return 'Invalid'
    const date = new Date(epochTime * 1000)
    return date.toLocaleTimeString()
}
const decodedToken = (token) => {
    try {
        return jwtDecode(token) //
    } catch (error) {
        return null
    }
}
const checkTokenExpired = (token) => {
    const decodeToken = decodedToken(token)
    if (!decodeToken) return true
    const currentTime = Date.now() / 1000

    return decodeToken.exp < currentTime
}

const checkTokenAboutToExpire = (token, bufferTime = 300) => {
    const decodeToken = decodedToken(token)
    if (!decodeToken) return true
    const currentTime = Date.now() / 1000

    return decodeToken.exp - currentTime <= bufferTime
}
const tokenUtils = {
    epochToDateTime,
    decodedToken,
    checkTokenExpired,
    checkTokenAboutToExpire
}

export default tokenUtils
