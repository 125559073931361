
const apiBaseUrl =
  process.env.REACT_APP_API_BASE_URL || "https://m6qvdwqeyf.execute-api.ap-south-1.amazonaws.com/int";

export const apiConfig = 
{
     apiBaseUrl: apiBaseUrl,
    "endpoints": {
        "login": "/auth/signin",
        "logout": "/auth/signout",
        "refreshToken": "/auth/refresh-token",
        "relatedContents": "/dashboard/related-contents",
        "storyLine": "/dashboard/story-line",
        "ingestion": "/dashboard/ingestion"
    },
    "headers": {
        "Accept": "application/json",
        "Content-Type": "application/json"
    }
}

