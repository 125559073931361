import React from 'react'
import { Snackbar } from '@mui/material'
import MuiAlert from '@mui/material/Alert'

const AlertComponent = ({ open, message, severity, onClose, autoHideDuration = 6000 }) => {
    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration}
            onClose={onClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
            <MuiAlert onClose={onClose} severity={severity} elevation={6} variant="filled" sx={{ width: '100%' }}>
                {message}
            </MuiAlert>
        </Snackbar>
    )
}

export default AlertComponent
