import React from 'react'
import logo from '../assets/images/logo-icon-darkbg.webp' // Todo
const Header = () => {
    return (
        <header>
            <div className="logo">
                <img src={logo} alt="GAIMAN" />
            </div>
            <nav>
                <ul></ul>
            </nav>
        </header>
    )
}

export default Header
