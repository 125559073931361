import React from 'react'
import { ToggleButton, ToggleButtonGroup} from '@mui/material'
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'

const CustomToggleButtonGroup = ({ value, options, onChange, groupStyle, buttonStyle , onCustomClick}) => {
    return (
        <>
            <ToggleButtonGroup
                value={value}
                exclusive
                onChange={onChange}
                aria-label="toggle button group"
                style={{ border: 'none', borderRadius: '10px', backgroundColor: 'white', ...groupStyle }}
            >
                {options?.map((option) => (
                    <ToggleButton
                        key={option.value}
                        value={option.value}
                        style={{
                            color: '#1a73e8',
                            fontWeight: 'bold',
                            border: 'none',
                            borderRadius: '10px',
                            ...buttonStyle
                        }}
                    >
                        {option.label}
                    </ToggleButton>
                ))}
                <ToggleButton
                    value="custom"
                    aria-label="Custom Range"
                    sx={{ color: '#1a73e8', border: 'none' }}
                    onClick={onCustomClick}
                >
                    Custom
                    <CalendarTodayIcon sx={{ marginRight: 1, color: '#1a73e8' }} />
                </ToggleButton>
            </ToggleButtonGroup>
            {/* <Button
                    value="custom"
                    aria-label="Custom Range"
                    sx={{ color: '#1a73e8', border: 'none' }}
                    onClick={onCustomClick}
                    
                >
                    Custom
                    <CalendarTodayIcon sx={{ marginRight: 1, color: '#1a73e8' }} />
                </Button> */}
        </>
    )
}

export default CustomToggleButtonGroup
