/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
// import { FaTwitter, FaFacebook, FaInstagram, FaDribbble, FaBehance } from "react-icons/fa";
import logo from '../assets/images/logo-light.webp'
import { Link } from 'react-router-dom'; // Import Link from React Router
const Footer = () => {
    return (
        <footer
            style={{
                backgroundColor: '#9090ea',
                width: '100%',
                bottom: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '0.5rem'
            }}
        >
            {/* Left Section (Logo) */}
            <div>
                <img src={logo} alt="Logo" height={70} width={50} />
                <div className="flex items-center space-x-2 mb-4 md:mb-2 justify-center">
                    {/* <h1 className="text-gray-800 font-light text-sm">
                            The <i>Intelligence</i> Behind the Story
                        </h1> */}
                </div>

                {/*Todo:  Social Media Icons */}
                <div className="flex space-x-4">
                    {/* <a href="#" className="text-gray-400 hover:text-white"><FaTwitter size={20} /></a>
            <a href="#" className="text-gray-400 hover:text-white"><FaFacebook size={20} /></a> */}
                </div>
            </div>

           {/* Todo- AboutUs and Contact Us Pages as per suggection, Center Section (Navigation Links) */}
           <div style={{ display: 'flex', gap: '1.5rem' }}>
                {/* Update links using React Router's Link */}
                <Link to="https://shorttok.com/" className="hover:text-white">
                    About Us
                </Link>
                <Link to="https://shorttok.com/#contact" className="hover:text-white">
                    Contact Us
                </Link>
            </div>
            {/* Bottom Section (Copyright) */}
            <div>© {new Date().getFullYear()} ShortTok,Inc.</div>
        </footer>
    )
}

export default Footer
