import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Box } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs from 'dayjs'

function CustomDatePicker({ open, onClose, onApply }) {
    const [startTime, setStartTime] = React.useState(null)
    const [endTime, setEndTime] = React.useState(null)

    // Calculate min & max dates
    const maxDate = dayjs()
    const minDate = dayjs().subtract(1, 'year')
    const handleApply = () => {
        if (startTime && endTime) {
            onApply({ startTime, endTime })
        }
        onClose() // Close the dialog
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
            <DialogTitle sx={{ fontSize: '1rem', paddingBottom: '1rem' }}>Select Date and Time</DialogTitle>
            <DialogContent>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <Box display="flex" flexDirection="column" gap={2} sx={{ height: '130px', overflowY: 'auto' }}>
                        <DateTimePicker
                            label="Start Time"
                            value={startTime}
                            onChange={setStartTime}
                            minDate={minDate}
                            maxDate={maxDate}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    label: 'Start Time',
                                    sx: {
                                        '& .MuiInputLabel-root': {
                                            paddingTop: '7px',
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }
                                    },
                                    InputLabelProps: {
                                        shrink: true,
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }
                                    }
                                }
                            }}
                        />
                        <DateTimePicker
                            label="End Time"
                            value={endTime}
                            onChange={setEndTime}
                            minDate={minDate}
                            maxDate={maxDate}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    label: 'End Time',
                                    InputLabelProps: {
                                        shrink: true,
                                        style: {
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            maxWidth: '100%'
                                        }
                                    }
                                }
                            }}
                        />
                    </Box>
                </LocalizationProvider>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <Button onClick={handleApply}>Apply</Button>
            </DialogActions>
        </Dialog>
    )
}

export default CustomDatePicker
